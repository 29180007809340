.sec12-container {
    position: relative;
    background-color: #f9e8d6;
    text-align: center;
    width: 100%; /* コンテナの幅を指定 */
    max-width: 100vh; /* 最大幅を設定 */
    margin: auto; /* 中央寄せ */
}

.sec12-answer-input,
.sec12-submit-button {
  display: block;
  border-radius: 5px;
  font-size: large;
  margin: 0 auto;
}

.text {
    color: #3e210d;
}
  
.button {
    border-radius: 5px;
    font-size: large; 
    margin: auto 0;
    margin-bottom: 20px;
}

.sec12-Q {
    height: auto;
    width: 65%;
}