.second-container {
    width: 100%;
    min-height: 100vh;
    background-color: #f9e8d6;
    text-align: center;
}

.text {
  color: #3e210d;
}

.second-answer-input,
.second-submit-button {
  border-radius: 5px;
  font-size: large;
  margin: 0 auto; 
}

.second-form {
  margin-bottom: 20px; /* フォームの下部に余白を追加 */
}