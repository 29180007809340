.sec10-container {
    position: relative;
    background-color: #f9e8d6;
    text-align: center;
    width: 100%; /* コンテナの幅を指定 */
    max-width: 100vh; /* 最大幅を設定 */
    margin: auto; /* 中央寄せ */
}

.sec10-answer-input,
.sec10-submit-button {
  display: block;
  border-radius: 5px;
  font-size: large;
  margin: 0 auto;
}

.text {
    color: #3e210d;
}
  
.button {
    border-radius: 5px;
    font-size: large; 
    margin: auto 0;
    margin-bottom: 20px;
}

.img-container-33 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3列を均等に配置 */
    grid-gap: 10px; /* グリッドアイテム間の隙間 */
    max-width: 100%; /* コンテナの最大幅を画面幅に合わせる */
    margin: auto; /* 中央揃え */
}

.img-container-33 img {
    width: 100%; /* 画像をグリッドセルに収める */
    height: auto; /* アスペクト比を保持 */
    object-fit: cover;
}

.img-normal {
    width: 70%;
    height: auto;
}