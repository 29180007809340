.sec6-container {
    background-color: #f9e8d6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

@media (max-width: 405px) {
    .white-circle {
        background-color: white;
        width: 50px;
        height: 50px;
    }
    
    .black-circle {
        background-color: black;
        width: 104px;
        height: 104px
    }
    
    .white-circle-2 {
        background-color: white;
        width: 150px;
        height: 150px;
    }

    .outer-rectangle {
        position: relative;
        background-color: black;
        width: 300px; /* 長方形の幅 */
        height: 300px; /* 長方形の高さ */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dots-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .dot {
        position: absolute;
        z-index: 2;
        width: 12px; /* 点のサイズ */
        height: 12px; /* 点のサイズ */
        background-color: white;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
    
    #dotA1 { top: 50px; left: 150px; }
    #dotA2 { top: 75px; left: 220px; }
    #dotA3 { top: 150px; left: 250px; }
    #dotA4 { top: 225px; left: 220px; }
    #dotA5 { top: 250px; left: 150px; }
    #dotA6 { top: 225px; left: 80px; }
    #dotA7 { top: 150px; left: 50px; }
    #dotA8 { top: 75px; left: 80px; }
    
    #dotB1 { top: 75px; left: 150px; }
    #dotB2 { top: 112px; left: 215px; }
    #dotB3 { top: 188px; left: 215px; }
    #dotB4 { top: 225px; left: 150px; }
    #dotB5 { top: 188px; left: 85px; }
    #dotB6 { top: 112px; left: 85px; }
    
    #dotC1 { top: 103px; left: 133px; }
    #dotC2 { top: 103px; left: 167px; }
    #dotC3 { top: 125px; left: 193px; }
    #dotC4 { top: 159px; left: 199px; }
    #dotC5 { top: 188px; left: 182px; }
    #dotC6 { top: 200px; left: 150px; }
    #dotC7 { top: 188px; left: 118px; }
    #dotC8 { top: 159px; left: 101px; }
    #dotC9 { top: 125px; left: 107px; }
    
    #dotD1 { top: 125px; left: 150px; }
    #dotD2 { top: 137px; left: 172px; }
    #dotD3 { top: 163px; left: 172px; }
    #dotD4 { top: 175px; left: 150px; }
    #dotD5 { top: 163px; left: 128px; }
    #dotD6 { top: 137px; left: 128px; }
    
    #dotE1 { top: 150px; left: 150px; }

    .line {
        position: absolute;
        z-index: 1;
        height: 2px; /* 線の太さ */
        transform-origin: left; /* 回転の基点を左端に設定 */
    }
    
    #lineAB11 {
        top: 50px;
        left: 150px;
        width: 25px;
        background-color: white;
        transform: rotate(90deg);
    }
    
    #lineAB12 {
        top: 50px;
        left: 150px;
        width: 85px;
        background-color: white;
        transform: rotate(42deg);
    }
    
    #lineAB16 {
        top: 50px;
        left: 150px;
        width: 85px;
        background-color: white;
        transform: rotate(138deg);
    }
    
    #lineAB21 {
        top: 75px;
        left: 220px;
        width: 75px;
        background-color: white;
        transform: rotate(180deg);
    }
    
    #lineAB22 {
        top: 75px;
        left: 220px;
        width: 34px;
        background-color: white;
        transform: rotate(98deg);
    }
    
    #lineAB32 {
        top: 150px;
        left: 250px;
        width: 59px;
        background-color: white;
        transform: rotate(228deg);
    }
    
    #lineAB33 {
        top: 150px;
        left: 250px;
        width: 59px;
        background-color: white;
        transform: rotate(132deg);
    }
    
    #lineAB43 {
        top: 225px;
        left: 220px;
        width: 34px;
        background-color: white;
        transform: rotate(-98deg);
    }
    
    #lineAB44 {
        top: 225px;
        left: 220px;
        width: 75px;
        background-color: white;
        transform: rotate(180deg);
    }
    
    #lineAB54 {
        top: 250px;
        left: 150px;
        width: 25px;
        background-color: white;
        transform: rotate(-90deg);
    }
    
    #lineAB53 {
        top: 250px;
        left: 150px;
        width: 85px;
        background-color: white;
        transform: rotate(-42deg);
    }
    
    #lineAB55 {
        top: 250px;
        left: 150px;
        width: 85px;
        background-color: white;
        transform: rotate(-138deg);
    }
    
    #lineAB64 {
        top: 225px;
        left: 80px;
        width: 75px;
        background-color: white;
        transform: rotate(0deg);
    }
    
    #lineAB65 {
        top: 225px;
        left: 80px;
        width: 68px;
        background-color: white;
        transform: rotate(-82deg);
    }
    
    #lineAB75 {
        top: 150px;
        left: 50px;
        width: 59px;
        background-color: white;
        transform: rotate(48deg);
    }
    
    #lineAB76 {
        top: 150px;
        left: 50px;
        width: 59px;
        background-color: white;
        transform: rotate(312deg);
    }
    
    #lineAB86 {
        top: 75px;
        left: 80px;
        width: 34px;
        background-color: white;
        transform: rotate(82deg);
    }
    
    #lineAB81 {
        top: 75px;
        left: 80px;
        width: 75px;
        background-color: white;
        transform: rotate(0deg);
    }
    
    #lineBC11 {
        top: 75px;
        left: 150px;
        width: 30px;
        background-color: black;
        transform: rotate(125deg);
    }
    
    #lineBC12 {
        top: 75px;
        left: 150px;
        width: 30px;
        background-color: black;
        transform: rotate(55deg);
    }
    
    #lineBC22 {
        top: 112px;
        left: 215px;
        width: 46px;
        background-color: black;
        transform: rotate(194deg);
    }
    
    #lineBC23 {
        top: 112px;
        left: 215px;
        width: 25px;
        background-color: black;
        transform: rotate(150deg);
    }
    
    #lineBC34 {
        top: 188px;
        left: 215px;
        width: 33px;
        background-color: black;
        transform: rotate(243deg);
    }
    
    #lineBC35 {
        top: 188px;
        left: 215px;
        width: 30px;
        background-color: black;
        transform: rotate(183deg);
    }
    
    #lineBC45 {
        top: 225px;
        left: 150px;
        width: 50px;
        background-color: black;
        transform: rotate(312deg);
    }
    
    #lineBC46 {
        top: 225px;
        left: 150px;
        width: 25px;
        background-color: black;
        transform: rotate(270deg);
    }
    
    #lineBC57 {
        top: 188px;
        left: 85px;
        width: 35px;
        background-color: black;
        transform: rotate(0deg);
    }
    
    #lineBC58 {
        top: 188px;
        left: 85px;
        width: 35px;
        background-color: black;
        transform: rotate(297deg);
    }
    
    #lineBC68 {
        top: 112px;
        left: 85px;
        width: 50px;
        background-color: black;
        transform: rotate(72deg);
    }
    
    #lineBC69 {
        top: 112px;
        left: 85px;
        width: 25px;
        background-color: black;
        transform: rotate(30deg);
    }
    
    #lineCD11 {
        top: 103px;
        left: 133px;
        width: 25px;
        background-color: white;
        transform: rotate(48deg);
    }
    
    #lineCD21 {
        top: 103px;
        left: 167px;
        width: 25px;
        background-color: white;
        transform: rotate(130deg);
    }
    
    #lineCD22 {
        top: 103px;
        left: 167px;
        width: 30px;
        background-color: white;
        transform: rotate(82deg);
    }
    
    #lineCD32 {
        top: 125px;
        left: 193px;
        width: 24px;
        background-color: white;
        transform: rotate(155deg);
    }
    
    #lineCD43 {
        top: 159px;
        left: 199px;
        width: 25px;
        background-color: white;
        transform: rotate(175deg);
    }
    
    #lineCD53 {
        top: 188px;
        left: 182px;
        width: 29px;
        background-color: white;
        transform: rotate(247deg);
    }
    
    #lineCD54 {
        top: 188px;
        left: 182px;
        width: 34px;
        background-color: white;
        transform: rotate(201deg);
    }
    
    #lineCD64 {
        top: 200px;
        left: 150px;
        width: 25px;
        background-color: white;
        transform: rotate(270deg);
    }
    
    #lineCD75 {
        top: 188px;
        left: 118px;
        width: 25px;
        background-color: white;
        transform: rotate(294deg);
    }
    
    #lineCD85 {
        top: 159px;
        left: 101px;
        width: 25px;
        background-color: white;
        transform: rotate(6deg);
    }
    
    #lineCD86 {
        top: 159px;
        left: 101px;
        width: 33px;
        background-color: white;
        transform: rotate(-40deg);
    }
    
    #lineCD96 {
        top: 125px;
        left: 107px;
        width: 24px;
        background-color: white;
        transform: rotate(28deg);
    }
    
    #lineDE11 {
        top: 125px;
        left: 150px;
        width: 25px;
        background-color: black;
        transform: rotate(90deg);
    }
    
    #lineDE21 {
        top: 137px;
        left: 172px;
        width: 25px;
        background-color: black;
        transform: rotate(150deg);
    }
    
    #lineDE31 {
        top: 163px;
        left: 172px;
        width: 25px;
        background-color: black;
        transform: rotate(210deg);
    }
    
    #lineDE41 {
        top: 175px;
        left: 150px;
        width: 25px;
        background-color: black;
        transform: rotate(-90deg);
    }
    
    #lineDE51 {
        top: 163px;
        left: 128px;
        width: 25px;
        background-color: black;
        transform: rotate(-30deg);
    }
    
    #lineDE61 {
        top: 137px;
        left: 128px;
        width: 25px;
        background-color: black;
        transform: rotate(30deg);
    }
}

@media (min-width: 405px) {
    .white-circle {
        background-color: white;
        width: 100px;
        height: 100px;
    }
    
    .black-circle {
        background-color: black;
        width: 208px;
        height: 208px
    }
    
    .white-circle-2 {
        background-color: white;
        width: 300px;
        height: 300px;
    }

    .outer-rectangle {
        position: relative;
        background-color: black;
        width: 500px; /* 長方形の幅 */
        height: 500px; /* 長方形の高さ */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dots-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    
    .dot {
        position: absolute;
        z-index: 2;
        width: 12px; /* 点のサイズ */
        height: 12px; /* 点のサイズ */
        background-color: white;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
    
    #dotA1 { top: 50px; left: 250px; }
    #dotA2 { top: 100px; left: 400px; }
    #dotA3 { top: 250px; left: 450px; }
    #dotA4 { top: 400px; left: 400px; }
    #dotA5 { top: 450px; left: 250px; }
    #dotA6 { top: 400px; left: 100px; }
    #dotA7 { top: 250px; left: 50px; }
    #dotA8 { top: 100px; left: 100px; }
    
    #dotB1 { top: 100px; left: 250px; }
    #dotB2 { top: 162px; left: 370px; }
    #dotB3 { top: 338px; left: 370px; }
    #dotB4 { top: 400px; left: 250px; }
    #dotB5 { top: 338px; left: 130px; }
    #dotB6 { top: 162px; left: 130px; }
    
    #dotC1 { top: 160px; left: 210px; }
    #dotC2 { top: 160px; left: 290px; }
    #dotC3 { top: 210px; left: 340px; }
    #dotC4 { top: 269px; left: 345px; }
    #dotC5 { top: 324px; left: 315px; }
    #dotC6 { top: 350px; left: 250px; }
    #dotC7 { top: 324px; left: 185px; }
    #dotC8 { top: 269px; left: 155px; }
    #dotC9 { top: 210px; left: 160px; }
    
    #dotD1 { top: 200px; left: 250px; }
    #dotD2 { top: 225px; left: 293px; }
    #dotD3 { top: 275px; left: 293px; }
    #dotD4 { top: 300px; left: 250px; }
    #dotD5 { top: 275px; left: 207px; }
    #dotD6 { top: 225px; left: 207px; }
    
    #dotE1 { top: 250px; left: 250px; }
    
    .line {
        position: absolute;
        z-index: 1;
        height: 2px; /* 線の太さ */
        transform-origin: left; /* 回転の基点を左端に設定 */
    }
    
    #lineAB11 {
        top: 50px;
        left: 250px;
        width: 50px;
        background-color: white;
        transform: rotate(90deg);
    }
    
    #lineAB12 {
        top: 50px;
        left: 250px;
        width: 164px;
        background-color: white;
        transform: rotate(42.5deg);
    }
    
    #lineAB16 {
        top: 50px;
        left: 250px;
        width: 164px;
        background-color: white;
        transform: rotate(137.5deg);
    }
    
    #lineAB21 {
        top: 100px;
        left: 400px;
        width: 150px;
        background-color: white;
        transform: rotate(180deg);
    }
    
    #lineAB22 {
        top: 100px;
        left: 400px;
        width: 68px;
        background-color: white;
        transform: rotate(115deg);
    }
    
    #lineAB32 {
        top: 250px;
        left: 450px;
        width: 119px;
        background-color: white;
        transform: rotate(228deg);
    }
    
    #lineAB33 {
        top: 250px;
        left: 450px;
        width: 119px;
        background-color: white;
        transform: rotate(132deg);
    }
    
    #lineAB43 {
        top: 400px;
        left: 400px;
        width: 68px;
        background-color: white;
        transform: rotate(245deg);
    }
    
    #lineAB44 {
        top: 400px;
        left: 400px;
        width: 150px;
        background-color: white;
        transform: rotate(180deg);
    }
    
    #lineAB54 {
        top: 450px;
        left: 250px;
        width: 50px;
        background-color: white;
        transform: rotate(-90deg);
    }
    
    #lineAB53 {
        top: 450px;
        left: 250px;
        width: 164px;
        background-color: white;
        transform: rotate(-42.5deg);
    }
    
    #lineAB55 {
        top: 450px;
        left: 250px;
        width: 164px;
        background-color: white;
        transform: rotate(-137.5deg);
    }
    
    #lineAB64 {
        top: 400px;
        left: 100px;
        width: 150px;
        background-color: white;
        transform: rotate(0deg);
    }
    
    #lineAB65 {
        top: 400px;
        left: 100px;
        width: 68px;
        background-color: white;
        transform: rotate(295deg);
    }
    
    #lineAB75 {
        top: 250px;
        left: 50px;
        width: 119px;
        background-color: white;
        transform: rotate(48deg);
    }
    
    #lineAB76 {
        top: 250px;
        left: 50px;
        width: 119px;
        background-color: white;
        transform: rotate(312deg);
    }
    
    #lineAB86 {
        top: 100px;
        left: 100px;
        width: 68px;
        background-color: white;
        transform: rotate(65deg);
    }
    
    #lineAB81 {
        top: 100px;
        left: 100px;
        width: 150px;
        background-color: white;
        transform: rotate(0deg);
    }
    
    #lineBC11 {
        top: 100px;
        left: 250px;
        width: 70px;
        background-color: black;
        transform: rotate(125deg);
    }
    
    #lineBC12 {
        top: 100px;
        left: 250px;
        width: 70px;
        background-color: black;
        transform: rotate(55deg);
    }
    
    #lineBC22 {
        top: 162px;
        left: 370px;
        width: 78px;
        background-color: black;
        transform: rotate(183deg);
    }
    
    #lineBC23 {
        top: 162px;
        left: 370px;
        width: 50px;
        background-color: black;
        transform: rotate(122deg);
    }
    
    #lineBC34 {
        top: 338px;
        left: 370px;
        width: 80px;
        background-color: black;
        transform: rotate(251deg);
    }
    
    #lineBC35 {
        top: 338px;
        left: 370px;
        width: 50px;
        background-color: black;
        transform: rotate(196deg);
    }
    
    #lineBC45 {
        top: 400px;
        left: 250px;
        width: 100px;
        background-color: black;
        transform: rotate(312deg);
    }
    
    #lineBC46 {
        top: 400px;
        left: 250px;
        width: 50px;
        background-color: black;
        transform: rotate(270deg);
    }
    
    #lineBC57 {
        top: 338px;
        left: 130px;
        width: 50px;
        background-color: black;
        transform: rotate(345deg);
    }
    
    #lineBC58 {
        top: 338px;
        left: 130px;
        width: 70px;
        background-color: black;
        transform: rotate(289deg);
    }
    
    #lineBC68 {
        top: 162px;
        left: 130px;
        width: 110px;
        background-color: black;
        transform: rotate(78deg);
    }
    
    #lineBC69 {
        top: 162px;
        left: 130px;
        width: 50px;
        background-color: black;
        transform: rotate(57deg);
    }
    
    #lineCD11 {
        top: 160px;
        left: 210px;
        width: 55px;
        background-color: white;
        transform: rotate(46deg);
    }
    
    #lineCD21 {
        top: 160px;
        left: 290px;
        width: 55px;
        background-color: white;
        transform: rotate(134deg);
    }
    
    #lineCD22 {
        top: 160px;
        left: 290px;
        width: 66px;
        background-color: white;
        transform: rotate(87deg);
    }
    
    #lineCD32 {
        top: 210px;
        left: 340px;
        width: 49px;
        background-color: white;
        transform: rotate(162deg);
    }
    
    #lineCD43 {
        top: 269px;
        left: 345px;
        width: 53px;
        background-color: white;
        transform: rotate(175deg);
    }
    
    #lineCD53 {
        top: 324px;
        left: 315px;
        width: 59px;
        background-color: white;
        transform: rotate(247deg);
    }
    
    #lineCD54 {
        top: 324px;
        left: 315px;
        width: 68px;
        background-color: white;
        transform: rotate(201deg);
    }
    
    #lineCD64 {
        top: 350px;
        left: 250px;
        width: 50px;
        background-color: white;
        transform: rotate(270deg);
    }
    
    #lineCD75 {
        top: 324px;
        left: 185px;
        width: 55px;
        background-color: white;
        transform: rotate(294deg);
    }
    
    #lineCD85 {
        top: 269px;
        left: 155px;
        width: 52px;
        background-color: white;
        transform: rotate(6deg);
    }
    
    #lineCD86 {
        top: 269px;
        left: 155px;
        width: 66px;
        background-color: white;
        transform: rotate(-40deg);
    }
    
    #lineCD96 {
        top: 210px;
        left: 160px;
        width: 48px;
        background-color: white;
        transform: rotate(18deg);
    }
    
    #lineDE11 {
        top: 200px;
        left: 250px;
        width: 50px;
        background-color: black;
        transform: rotate(90deg);
    }
    
    #lineDE21 {
        top: 225px;
        left: 293px;
        width: 50px;
        background-color: black;
        transform: rotate(150deg);
    }
    
    #lineDE31 {
        top: 275px;
        left: 294px;
        width: 50px;
        background-color: black;
        transform: rotate(210deg);
    }
    
    #lineDE41 {
        top: 300px;
        left: 250px;
        width: 50px;
        background-color: black;
        transform: rotate(-90deg);
    }
    
    #lineDE51 {
        top: 275px;
        left: 207px;
        width: 50px;
        background-color: black;
        transform: rotate(-30deg);
    }
    
    #lineDE61 {
        top: 225px;
        left: 207px;
        width: 50px;
        background-color: black;
        transform: rotate(30deg);
    }
}

.number-display {
    position: absolute;
    top: 0; /* 親要素の上端に配置 */
    right: 0; /* 親要素の右端に配置 */
    padding: 10px; /* 見た目の調整 */
    background-color: #f0f0f0; /* 背景色 */
    border-radius: 5px; /* 角丸の見た目 */
}

.text-center {
    text-align: center;
}

.button {
    margin-top: 20px;
}