.third-page {
    position: relative;
    background-color: #f9e8d6;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    margin-top: 1px;
}

.text {
    color: #3e210d;
}

.button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.answer-input {
    display: block;
    border-radius: 5px;
    font-size: large;
    margin: 0 auto;
    margin-bottom: 3px;
}

.padded {
    background-color: #b8d06c;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    margin: auto 0;
}

.last-img {
    width: 90%;
    height: auto;
    max-width: 600px;
}
