.map-tmp {
    position: relative;
    background-color: #f9e8d6;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    margin-top: 1px;
}

.text {
    color: #3e210d;
}

.example {
    width: 90%;
    height: auto;
    max-width: 500px;
}

.back-button {
    border-radius: 5px;
    font-size: large; 
    margin: auto 0;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}