.sec1-container {
    width: 100%;
    min-height: 100vh;
    background-color: #f9e8d6;
    text-align: center;
}

.sec1-answer-input,
.sec1-submit-button {
  border-radius: 5px;
  font-size: large;
  display: block; /* ブロックレベル要素として表示 */
  margin: 0 auto; /* 水平方向の中央揃え */
  margin-bottom: 10px; /* 下部に余白を追加 */
}

.sec1-form {
  margin-bottom: 20px; /* フォームの下部に余白を追加 */
}

.text {
  color: #3e210d;
}

.button {
  border-radius: 5px;
  font-size: large; 
  margin: auto 0;
  margin-bottom: 20px;
}

.diary {
  width: 70%;
  height: auto;
  max-width: 600px;
  margin: 20px auto;
  display: inline-block;
}

.diary img {
  max-width: 100%; /* 画像の最大幅をコンテナの幅に合わせる */
  height: auto; /* 高さを自動調整してアスペクト比を保持 */
}

.sec1-main {
  position: relative;
}