.opening {
    position: relative;
    background-color: #f9e8d6;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    margin-top: 1px;
}

.Rechord-MV {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    max-width: 700px;
}

.text {
    color: #3e210d;
}

.button {
    border-radius: 5px;
    font-size: large; 
    margin: auto 0;
}

.attention-img {
    width: 90%;
    height: auto;
    max-width: 630px;
}