.sec11-container {
  position: relative;
  background-color: #f9e8d6;
  text-align: center;
  width: 100%; /* コンテナの幅を指定 */
  max-width: 100vh; /* 最大幅を設定 */
  margin-top: 1px;
  margin-inline: auto;
}
body {
  background-color: #f9e8d6;
}

.sec11-main-field {
  position: relative;
  width: 300px; /* コンテナの幅 */
  height: 300px; /* 画面の高さに合わせる */
  margin: 0 auto; /* 中央揃え */
}

.sec11-pyramid {
  height: auto;
  width: 100%;
}

.img1 {
  position: absolute;
  top: 70%;
  left: 16%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img2 {
  position: absolute;
  top: 70%;
  left: 30%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img3 {
  position: absolute;
  top: 70%;
  left: 44%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img4 {
  position: absolute;
  top: 70%;
  left: 58%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img5 {
  position: absolute;
  top: 53.8%;
  left: 22%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img6 {
  position: absolute;
  top: 53.8%;
  left: 37%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img7 {
  position: absolute;
  top: 53.8%;
  left: 52%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img8 {
  position: absolute;
  top: 37.8%;
  left: 30%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img9 {
  position: absolute;
  top: 37.8%;
  left: 44%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.img10 {
  position: absolute;
  top: 22%;
  left: 37%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.key-yellow {
  position: absolute;
  top: 70%;
  left: 90%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.key-black {
  position: absolute;
  top: 53.8%;
  left: 90%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.key-blue {
  position: absolute;
  top: 38%;
  left: 90%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.key-red {
  position: absolute;
  top: 22%;
  left: 90%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.key-white {
  position: absolute;
  top: 6%;
  left: 90%;
  width: auto;
  height: 12%;
  cursor: pointer;
}

.sec11-form {
  margin-bottom: 10px; /* フォームの下部に余白を追加 */
}

.sec11-popup-container {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* 親要素（背景画像）の90%の幅 */
  height: 50%; /* アスペクト比を保持 */
  max-height: 500px;
  pointer-events: auto;
  background-color: whitesmoke;
  z-index: 1000;
}

.sec11-popup-image {
  width: 80%;
  height: auto;
  margin-top: 20px;
  max-width: 380px;
  /* margin-bottom: -400px; */
}

.sec11-popup-input {
  position: absolute;
  display: block; /*ブロックレベル要素として表示 */
  text-align: center;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-size: 100%;
}

.sec11-submit-button {
  position: absolute;
  display: block; /*ブロックレベル要素として表示 */
  text-align: center;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-size: 100%;
}

.sec11-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  color: black;
}

.sec11-bold-red-text {
  color: red;
  font-weight: bold;
}

.text {
  color: #3e210d;
}

.button {
  border-radius: 5px;
  font-size: large;
  margin: auto 0;
  margin-bottom: 20px;
}

.sec11-rule-image {
  width: 100%;
  height: auto;
}
