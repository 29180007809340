.sec8-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f9e8d6;
  text-align: center;
}

.sec8-answer-input,
.sec8-submit-button {
  display: block; /* ブロックレベル要素として表示 */
  border-radius: 5px;
  font-size: large;
  margin: 0 auto; /* 水平方向の中央揃え */
  margin-bottom: 10px; /* 下部に余白を追加 */
}

.sec8-form {
  margin-bottom: 20px; /* フォームの下部に余白を追加 */
}

.button {
  border-radius: 5px;
  font-size: large; 
  margin: auto 0;
  margin-bottom: 20px;
}

.text {
  color: #3e210d;
}

.padded {
  background-color: #b8d06c;
  padding: 1px 2px;
  border-radius: 5px;
  display: inline-block;
  margin: auto 0;
  margin-bottom: 10px;
}