.sec5-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f9e8d6;
  text-align: center;
}

.sec5-answer-input,
.sec5-submit-button {
  border-radius: 5px;
  font-size: large;
  display: block; /* ブロックレベル要素として表示 */
  margin: 0 auto; /* 水平方向の中央揃え */
  margin-bottom: 10px; /* 下部に余白を追加 */
}

.sec5-form {
  margin-bottom: 20px; /* フォームの下部に余白を追加 */
}

.text {
  color: #3e210d;
}

.sec5-img {
  height: auto;
  width: 80%;
  margin-bottom: 2px;
}
  