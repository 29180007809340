.sec2-container {
    width: 100%;
    min-height: 100vh;
    background-color: #f9e8d6;
    text-align: center;
}

.sec2-answer-input,
.sec2-submit-button {
  border-radius: 5px;
  font-size: large;
  display: block; /* ブロックレベル要素として表示 */
  margin: 0 auto; /* 水平方向の中央揃え */
  margin-bottom: 10px; /* 下部に余白を追加 */
}

.sec2-form {
  margin-bottom: 20px; /* フォームの下部に余白を追加 */
}

.text {
  color: #3e210d;
}

.button {
  border-radius: 5px;
  font-size: large; 
  margin: auto 0;
  margin-bottom: 20px;
}