/* src/components/MapPage.css */
.map-container {
  position: relative;
  width: 100%; /* コンテナの幅を指定 */
  max-width: 900px; /* 最大幅を設定 */
  background-color: #f9e8d6;
  margin: auto; /* 中央寄せ */
}

.background-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.popup-container-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000a;

  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  position: relative;
  width: 80%; /* 親要素（背景画像）の90%の幅 */
  max-width: 500px;
  height: auto; /* アスペクト比を保持 */
}

.popup-image {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 2%;
  right: 1%;
  width: 15%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  /* font-size: 2rem; */
  color: black;
  height: auto;
}

.record-image {
  position: absolute;
  width: 17% !important;
  top: 2%;
  right: 17%;
  width: 15%;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s linear;
}

.back-button {
  border-radius: 5px;
  font-size: large;
  margin: auto 0;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.map_select_btn {
  position: absolute;
  top: 0;
  left: 0;

  width: 40px;
  height: 40px;

  /* background-color: red; */
}
