.sec7-container {
    width: 100%;
    min-height: 100vh;
    background-color: #f9e8d6;
    text-align: center;
}
  
.sec7-text {
    margin-bottom: 20px;
}
  
.sec7-image {
    max-width: 80%;
    height: auto;
    margin-bottom: 20px;
}
  
.sec7-answer-input,
.sec7-submit-button {
  display: block;
  border-radius: 5px;
  font-size: large;
  margin: 0 auto; 
  margin-bottom: 10px; 
}

.sec4-form {
  margin-bottom: 5px; 
}

.text {
    color: #3e210d;
}

.button {
  border-radius: 5px;
  font-size: large; 
  margin: auto 0;
  margin-bottom: 20px;
}