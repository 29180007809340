.second-page {
    position: relative;
    background-color: #f9e8d6;
    width: 100%;
    min-height: 100vh;
    margin-top: 1px;
    text-align: center;
}

.text {
    color: #3e210d;
}

.text-center {
    color: #3e210d;
    text-align: center;
}

.button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.submit-button {
    display: block;
    border-radius: 5px;
    font-size: large;
    margin: 0 auto;
    margin-bottom: 10px;
}

.stage-select {
    width: 50%;
    height: auto;
    max-width: 400px;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
}