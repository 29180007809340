.sec9-container {
    position: relative;
    background-color: #f9e8d6;
    text-align: center;
    width: 100%; /* コンテナの幅を指定 */
    max-width: 100vh; /* 最大幅を設定 */
    margin: auto; /* 中央寄せ */
}

.sec9-answer-input,
.sec9-submit-button {
  display: block;
  border-radius: 5px;
  font-size: large;
  margin: 0 auto;
}

.sec9-form {
    margin-bottom: 5px; 
}

.text {
    color: #3e210d;
}
  
.button {
    border-radius: 5px;
    font-size: large; 
    margin: auto 0;
    margin-bottom: 20px;
}

.sec9-bingo {
    height: auto;
    width: 70%;
}