.sec4-container {
    position: relative;
    background-color: #f9e8d6;
    text-align: center;
    width: 100%; /* コンテナの幅を指定 */
    max-width: 100vh; /* 最大幅を設定 */
    margin: auto; /* 中央寄せ */
}

.sec4-popup-container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}
  
.sec4-text {
    margin-bottom: 20px;
}
  
.sec4-image {
    max-width: 80%;
    height: auto;
    margin-bottom: 20px;
    z-index: 1;
}

.sec4-rect {
    position: absolute;
    background-color: red;
}
  
.sec4-answer-input,
.sec4-submit-button {
  display: block;
  border-radius: 5px;
  font-size: large;
  margin: 0 auto; 
  margin-bottom: 10px;
}

.sec4-answer-input {
    margin-top: 20px;
}

.sec4-form {
  margin-bottom: 5px; 
}

.text {
    color: #3e210d;
}

.sec4-main-field {
    position: relative;
    width: 280px; /* コンテナの幅 */
    height: auto; /* 画面の高さに合わせる */
    margin: 0 auto; /* 中央揃え */
}

.sec4-1st {
    height: auto;
    width: 100%;
}

.sec4-Q123 {
    height: auto;
    width: 70%;
}

.red-rect {
    position: absolute;
    height: 3%; /* 線の太さ */
    transform-origin: left; /* 回転の基点を左端に設定 */
    background-color: red;
}

#rect1A {
    top: 41%;
    left: 42.5%;
    width: 15%;
    transform: rotate(0deg);
}

#rect1B {
    top: 45.6%;
    left: 37%;
    width: 15%;
    transform: rotate(90deg);
}

#rect2A {
    top: 45.6%;
    left: 63%;
    width: 15%;
    transform: rotate(90deg);
}

#rect2B {
    top: 62%;
    left: 42.5%;
    width: 15%;
    transform: rotate(0deg);
}

#rect3B {
    top: 66.8%;
    left: 37%;
    width: 15%;
    transform: rotate(90deg);
}

#rect2C {
    top: 66.8%;
    left: 63%;
    width: 15%;
    transform: rotate(90deg);
}

#rect3C {
    top: 83.5%;
    left: 42.5%;
    width: 15%;
    transform: rotate(0deg);
}
