.clear-page {
    position: relative;
    background-color: #f9e8d6;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    margin-top: 1px;
}

.text {
    color: #3e210d;
}

.button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.clear-img {
    width: 90%;
    height: auto;
    max-width: 600px;
}